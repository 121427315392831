/* eslint-disable indent */
import {DeviceCategory} from '@techsee/techsee-common/lib/constants/utils.constant';
import {Boundaries} from '@techsee/techsee-ui-common/lib/draggble-panel/contracts';
import {action, observable} from 'mobx';
import {getRootStore} from '../../app.bootstrap';
import {get} from 'lodash';

export interface IWifiAnalyzerResults {
    name: string;
    description: string;
    createdOn: Date;
    accountId: string;
    roomId: string;
    platformType: string;
    active?: boolean;
    wifiConnectionInfo: any;
    scanAreaResults: any[];
    networkDiscoveryDevices: any[];
    networkSpeed: any;
}

export interface IWifiAnalyzerController {
    readonly isVisible: boolean;
    readonly results: IWifiAnalyzerResults | null;

    readonly position: {x: number; y: number};
    readonly boundaries: Boundaries;
    readonly offset: Boundaries;
    readonly deviceCategory: DeviceCategory;
    readonly currentTab: number;
    readonly isMinimized: boolean;

    show(results: IWifiAnalyzerResults): void;
    hide(): void;
    minimize(): void;
    maximize(): void;
    setPosition(position: {x: number; y: number}): void;
    setCurrentTab(tab: number): void;
}

export class WifiAnalyzerController implements IWifiAnalyzerController {
    @observable isVisible: boolean = false;

    @observable results: IWifiAnalyzerResults | null = null;

    @observable boundaries: Boundaries = {
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
        top: 0
    };

    @observable offset: Boundaries = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    };

    @observable position: {x: number; y: number} = {x: 60, y: window.innerHeight - 400};
    @observable deviceCategory: DeviceCategory = DeviceCategory.desktop;
    @observable currentTab: number = 0;
    @observable isMinimized: boolean = false;

    constructor() {
        const environmentDetect = getRootStore().environmentService;
        const displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

        this.deviceCategory = environmentDetect.deviceCategory(displayTabletAsDesktop) || DeviceCategory.desktop;
    }

    @action
    hide = () => {
        this.isVisible = false;
        this.results = null;
    };

    @action
    show = (results: IWifiAnalyzerResults) => {
        this.results = results;
        this.isVisible = true;

        const newLeftBar = (document.getElementsByClassName('app-left-bar') as any)[0];
        const newTopBar = (document.getElementsByClassName('app-top-bar ') as any)[0];

        this.setOffset({
            left: get(newLeftBar, 'offsetWidth', 0),
            top: get(newTopBar, 'offsetHeight', 0),
            right: 0,
            bottom: 0
        });
    };

    @action
    setCurrentTab = (tab: number) => {
        this.currentTab = tab;
    };

    @action
    minimize = () => {
        this.isMinimized = true;

        const position = {
            y: this.boundaries.bottom - 60,
            x: 60
        };

        this.setPosition(position);
    };

    @action
    maximize = () => {
        this.isMinimized = false;

        const position = {
            y: this.boundaries.bottom - this.boundaries.bottom / 2 - 100,
            x: 60
        };

        this.setPosition(position);
    };

    @action
    setPosition = (position: {x: number; y: number}) => {
        this.position = position;
    };

    @action
    setOffset = (offset: Boundaries) => {
        this.offset = offset;
    };
}
