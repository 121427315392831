import React, {createRef, Fragment} from 'react';

import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import DraggblePanel from '@techsee/techsee-ui-common/lib/draggble-panel';
import {observer} from 'mobx-react';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';

import {IWifiAnalyzerController} from './controller';
import {ITab, SimpleTabs} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {NetworkTab} from './components/NetworkTab';
import './styles.scss';

interface WifiAnalyzerProps {
    ctrl: IWifiAnalyzerController;
}

@observer
export class WifiAnalyzer extends TechseeBaseComponent<WifiAnalyzerProps> {
    renderInternal(): JSX.Element {
        const {ctrl} = this.props;

        if (!ctrl.results) {
            return <Fragment />;
        }

        const tabs: ITab[] = [
            {
                title: 'NETWORK LIST',
                content: () => <NetworkTab results={ctrl.results!} />
            },
            {
                title: 'CHANNEL ANALYSIS',
                content: () => <></>
            }
        ];

        return (
            <DraggblePanel
                className='use-common-styles'
                bounds={ctrl.offset}
                position={ctrl.position}
                wrapperElementClass='dashboard-main-container'
                offset={ctrl.offset}
                disabled={ctrl.isMinimized}
                deviceCategory={ctrl.deviceCategory}
                handle={'.wifi-analyzer-header'}>
                <div className={`wifi-analyzer ${ctrl.isMinimized ? 'minimized' : ''}`}>
                    <div className='wifi-analyzer-header'>
                        <div className='wifi-analyzer-header-left wifi-analyzer-click-handle'>
                            <h1>{ctrl.results.name}</h1>
                            <span>{ctrl.results.createdOn?.toDateString() || new Date().toDateString()}</span>
                        </div>
                        <div className='wifi-analyzer-icons'>
                            <span onClick={() => (ctrl.isMinimized ? ctrl.maximize() : ctrl.minimize())}>
                                <Icon style={{color: 'white'}} iconName={ctrl.isMinimized ? 'maximize' : 'minimize'} />
                            </span>
                            <span onClick={() => this.props.ctrl.hide()}>
                                <Icon style={{color: 'white'}} iconName={'close'} />
                            </span>
                        </div>
                    </div>

                    <SimpleTabs
                        uid='wifi-analyzer-tabs'
                        tabsList={tabs}
                        initialTabIndex={ctrl.currentTab}
                        onChange={ctrl.setCurrentTab}
                    />
                </div>
            </DraggblePanel>
        );
    }
}
