import includes from 'lodash/includes';
import {getRootStore} from '../app.bootstrap';

function getCopilotUrl(): string {
    return includes(window.location.origin, 'localhost') ? 'http://localhost:5021' : window.location.origin;
}

function loadScript(url: string) {
    return new Promise((resolve, reject) => {
        const scriptElement = document.createElement('script');

        scriptElement.src = url;
        scriptElement.onload = resolve;
        scriptElement.onerror = reject;
        document.body.appendChild(scriptElement);
    });
}

const fullHeight = '80vh';
const sourceApp = 'Techsee-Live';

export interface ICopilotService {
    init(copilotElement: HTMLDivElement): Promise<void>;
    imageCaptured(url: string, base64: string): void;
    endMeeting(roomId: string): void;
    startMeeting(roomId: string): void;
    cameraPermissionSet(isCameraOn: boolean): void;
}

export class CopilotService implements ICopilotService {
    private copilot: any;
    private copilotSdk: any;
    private plugin: any;
    private readonly version: string;
    private readonly accountId: string;
    private readonly userId: string;
    private readonly isHosting: boolean;
    private readonly jsApiService: any;

    constructor(version: string, accountId: string, userId: string) {
        this.version = version;
        this.isHosting = window.self === window.top;
        this.accountId = accountId;
        this.userId = userId;
        this.jsApiService = getRootStore().jsApiService;
    }

    async loadSdkAndPlugin(): Promise<void> {
        const scriptUrl = includes(origin, 'localhost')
            ? `/js/copilot-sdk.${this.version}.min.js`
            : `${origin}/sophie/assistant/sdk/js/copilot-sdk.${this.version}.min.js`;

        await loadScript(scriptUrl);
        // @ts-ignore
        const copilotSdk = window.Copilot;

        this.copilotSdk = copilotSdk;

        const pluginConfig = {
            transportHub: this.isHosting
                ? this.copilotSdk.TransportHub.DirectMessageHub
                : this.copilotSdk.TransportHub.PostMessageHub,
            sourceApp: sourceApp
        };

        this.plugin = new this.copilotSdk.Plugin(pluginConfig);

        this.jsApiService.sophieAssistantReady();
    }

    async init(copilotElement: HTMLDivElement): Promise<void> {
        await this.loadSdkAndPlugin();

        if (this.isHosting) {
            await this.initCopilotApp(copilotElement);
        }
    }

    async initCopilotApp(copilotElement: HTMLDivElement) {
        const copilotSdkSettings = {
            copilotUrl: getCopilotUrl(),
            pluginTransports: [this.copilotSdk.TransportHub.DirectMessageHub],
            pluginOutputDestination: window,
            meta: {
                userId: this.userId,
                accountId: this.accountId
            }
        };
        const sdkConfig = {
            container: copilotElement,
            appearance: {
                height: fullHeight
            }
        };

        this.copilot = new this.copilotSdk.CopilotSdk(copilotSdkSettings);

        await this.copilot.init(sdkConfig);
    }

    startMeeting(externalSessionId: string) {
        const sessionExternalStarted = 'sessionExternalStartedSent';

        const message = {
            action: sessionExternalStarted,
            data: {externalSessionId}
        };

        // @ts-ignore
        this.plugin.sendMessage(message);
    }

    imageCaptured(url: string, base64: string): void {
        const imageCaptured = 'imageCaptureSent';

        const message = {
            action: imageCaptured,
            data: {base64, url}
        };

        // @ts-ignore
        this.plugin.sendMessage(message);
    }

    endMeeting(externalSessionId: string): void {
        const sessionExternalEnded = 'sessionExternalEndedSent';

        const message = {
            action: sessionExternalEnded,
            data: {externalSessionId: externalSessionId}
        };

        // @ts-ignore
        this.plugin.sendMessage(message);
    }

    cameraPermissionSet(isCameraOn: boolean): void {
        // @ts-ignore
        const cameraPermission = 'cameraPermissionSent';
        const message = {
            action: cameraPermission,
            data: isCameraOn
        };

        // @ts-ignore
        this.plugin.sendMessage(message);
    }
}
