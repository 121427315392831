import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {IWifiAnalyzerResults} from '../controller';

interface NetworkTabProps {
    results: IWifiAnalyzerResults;
}

export class NetworkTab extends TechseeBaseComponent<NetworkTabProps> {
    renderInternal() {
        const {results} = this.props;

        return (
            <div className='wifi-analyzer-content'>
                <div className={'item active'}>
                    <div className='wifi-analyzer-container'>
                        <div className='wifi-analyzer-container-left'>
                            <span className='wifi-analyzer-container-left-ssid'>
                                {results.wifiConnectionInfo && (
                                    <span className='wifi-analyzer-container-left-channel'>
                                        CH{results.wifiConnectionInfo.channel}
                                    </span>
                                )}
                                <span className='wifi-analyzer-container-left-name'>
                                    {results.wifiConnectionInfo?.ssid || results.name}
                                </span>
                            </span>
                            {results.wifiConnectionInfo && (
                                <span className='wifi-analyzer-container-left-mac'>
                                    {results.wifiConnectionInfo.macAddress}
                                </span>
                            )}
                        </div>
                        <div className='wifi-analyzer-container-right'>
                            {results.wifiConnectionInfo && (
                                <span className='green'>ᯤ {results.wifiConnectionInfo.signalLevel}%</span>
                            )}
                            {results.wifiConnectionInfo && (
                                <span className='green'>{results.wifiConnectionInfo.dBm} dBm</span>
                            )}
                            <div className='wifi-analyzer-container-badge'>Connected</div>
                        </div>
                    </div>
                    <div className='wifi-analyzer-container'>
                        <div className='wifi-analyzer-container-column'>
                            <div className='column-items'>
                                <Icon iconName={'lock'} /> Security
                                <span>{results.wifiConnectionInfo?.security || 'N/A'}</span>
                            </div>
                            <div className='column-items'>
                                <Icon iconName={'location'} /> Distance
                                <span>{results.wifiConnectionInfo?.distance || 'N/A'}</span>
                            </div>
                        </div>
                        <div className='wifi-analyzer-container-column'>
                            <div className='column-items'>
                                ↑ Upload
                                <span>{results.networkSpeed.upSpeed}</span>
                            </div>
                            <div className='column-items'>
                                ↓ Download
                                <span>{results.networkSpeed.downloadSpeed}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {results.scanAreaResults &&
                    results.scanAreaResults.map((device, idx) => (
                        <div key={idx} className='item'>
                            <div className='wifi-analyzer-container'>
                                <div className='wifi-analyzer-container-left'>
                                    <span className='wifi-analyzer-container-left-ssid'>
                                        <span className='wifi-analyzer-container-left-channel'>CH{device.channel}</span>
                                        <span className='wifi-analyzer-container-left-name'>{device.ssid}</span>
                                    </span>
                                    <span className='wifi-analyzer-container-left-mac'>{device.macAddress}</span>
                                </div>
                                <div className='wifi-analyzer-container-right'>
                                    <span className='green'>ᯤ {device.signalLevel}%</span>
                                    <span className='green'>{device.dBm} dBm</span>
                                </div>
                            </div>
                            <div className='wifi-analyzer-container'>
                                <div className='wifi-analyzer-container-column'>
                                    <div className='column-items'>
                                        <Icon iconName={'lock'} /> Security
                                        <span>{device.security}</span>
                                    </div>
                                    <div className='column-items'>
                                        <Icon iconName={'location'} /> Distance
                                        <span>{device.distance}</span>
                                    </div>
                                </div>
                                <div className='wifi-analyzer-container-column'>
                                    <div className='column-items'>
                                        ↑ Upload
                                        <span>N/A</span>
                                    </div>
                                    <div className='column-items'>
                                        ↓ Download
                                        <span>N/A</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}
